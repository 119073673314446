import Article from "@/templates/article/article"
import Page from "@/templates/page/page"
import Archive from "@/templates/archive/archive"
import Gift from "@/templates/gift/gift"
import Event from "@/templates/event/event"
import Campaign from "@/templates/campaign/campaign"

import Error from "@/templates/error/error"
import Protected from "@/templates/protected/protected"

export const templateMapping = {
  article: Article,
  page: Page,
  archive: Archive,
  gift: Gift,
  event: Event,
  campaign: Campaign,
  error: Error,
  protected: Protected
}
