import Image from "next/image"
import { useCopy } from "@/lib/contexts/appContext"
import styles from "./partners.module.css"

export default function Partners() {
  const copy = useCopy()
  const { heading, logos } = copy.footer.partners

  if (!logos || logos.length === 0) {
    return null
  }

  return (
    <div className="partners">
      {heading && <span className={styles.heading}>{heading}</span>}
      <ul className={styles.logos}>
        {logos.map((logo, index) => {
          const { url, image } = logo
          if (!image) {
            return null
          }

          return (
            <li key={url + index}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="logo">
                <Image
                  src={image.url}
                  width={image.width}
                  height={image.height}
                />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
