import Image from "next/image"
import { useCopy, usePropsObject } from "@/lib/contexts/appContext"
import { useTemplate } from "@/lib/helpers/template"
import Social from "@/common/social/social"
import Logo from "@/common/logos/logo"

import Primary from "./menus/primary"
import Secondary from "./menus/secondary"
import Tertiary from "./menus/tertiary"
import Template from "./template"
import Content from "./content"
import Partners from "./partners"
import Newsletter from "./newsletter"
import styles from "./footer.module.css"
import classNames from "classnames"

export default function Footer({ hasSidebar }) {
  const copy = useCopy()
  const object = usePropsObject()
  const blockTemplate = useTemplate()
  const { template, hideForm } = copy.footer
  const { year, publisher, ninetyLogoWhite } = copy.general
  const image = ninetyLogoWhite || false

  const classes = [
    styles.footer,
    ["article", "event"].includes(blockTemplate) ? "white" : null,
    object?.pageColor ? object.pageColor : null,
    blockTemplate === "campaign" ? "campaign-" + object?.theme?.bgColor : "",
    { "has-sidebar": hasSidebar }
  ]

  return (
    <footer className={classNames(classes)}>
      {<Template content={template} />}
      {!hideForm && <Newsletter />}
      <div className="inner-wrapper">
        <div className="logos" role="logos">
          <Logo className="logo" />
          <div role="wrapper">
            <>
              {image && (
                <figure className="ninety">
                  <Image
                    src={image.url}
                    sizes="256px"
                    width={image.width}
                    height={image.height}
                    alt={image.alt}
                  />
                </figure>
              )}
              {copy.general.pg && (
                <span className="inline-block mt-1 font-bold">
                  PG: {copy.general.pg}
                </span>
              )}
            </>
          </div>
        </div>
        <Primary />
        <Tertiary />
        <Content />
        <div className={styles.section} role="wrapper">
          <Partners />
          <Social styles={styles} location="primary" />
        </div>
        <div className="bottom-row" role="wrapper">
          <span className="publisher">
            &copy; {year} {publisher}
          </span>
          <Secondary />
        </div>
      </div>
    </footer>
  )
}
