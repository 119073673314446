import Link from "next/link"
import { useBreadcrumbs } from "@/lib/contexts/appContext"
import Block from "@/components/wrappers/block"
import styles from "./breadcrumbs.module.css"
import cx from "classnames"

function Breadcrumbs({
  crumbs = false,
  className,
  wrapperClassName,
  hidden = false,
  width = "full",
  gap = "small",
  type = "normal"
}) {
  const defaultCrumbs = useBreadcrumbs()

  if (!crumbs) {
    crumbs = defaultCrumbs
  }

  if (hidden) {
    crumbs = []
  }

  const breadcrumbs = crumbs.map((item, index) => {
    let crumb = item.label

    if (item.label === null) {
      return
    }

    if (typeof item.uri === "string") {
      crumb = (
        <Link href={item.uri} prefetch={false}>
          <a className="font-bold">{item.label}</a>
        </Link>
      )
    }

    return (
      <li className={styles.crumb} key={index}>
        {crumb}
      </li>
    )
  })

  const size = type === "large" ? styles.breadcrumbsLarge : null

  return (
    <Block
      block={{ name: "breadcrumbs" }}
      width={width}
      gap={gap}
      className={cx(wrapperClassName, size, "z-10")}>
      <ol className={cx(styles.list, className)}>{breadcrumbs}</ol>
    </Block>
  )
}

export default Breadcrumbs
